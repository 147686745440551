import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import Axios from "axios";
import FileSaver from "file-saver";
import {
  Loading,
  Layout,
  Table,
  Button,
  Tag,
  Form,
  Input,
  InputNumber,
  Select,
  Card,
  Dialog,
  Alert,
  MessageBox,
  Pagination,
  DateRangePicker,
  Tabs,
  Message,
} from "element-react";
import NavBar from "../../components/navBar/NavBar";
import ToolBar from "../../components/toolBar/ToolBar";
import Footer from "../../components/footer/Footer";
import GoogleMap from "../../components/map/Map";
//import signalSVG from '../../components/images/señal.svg';
//import { ReactComponent as SignalSVG } from '../../components/images/señal.svg';
import SignalSVG from "../../components/images/signal/Signal";
import "./Device.css";
import { Link } from "react-router-dom";
import { URL_API } from "../../constant";
import date from "date-and-time";
import { sectorListRequest } from "../Sector/SectorRequest";
import {
  deviceCreateRequest,
  deviceUpdateRequest,
  deviceDeleteRequest,
  paginationDeviceListRequest,
  getFilesByDevice,
  fileDeleteRequest,
  inactiveDevicesListRequest,
  statusValveRequest,
  commandsByModel,
  deviceProfileRequest,
  serviceProfileRequest,
  getDevicesHhu,
} from "./DeviceRequest";
import { contractListRequest } from "../Contract/ContractRequest";
import { modelListRequest } from "../Model/ModelRequest";
import moment from "moment-timezone";

function Device(props) {
  // variables para el encabezado de envio de la imagen del medidor
  const [headers, setHeaders] = useState({
    "x-access-token": localStorage.getItem("jwt"),
    organizationId: localStorage.getItem("organizationId"),
  });

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => {
    setStateToolBar(!stateToolBar);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSearch, setLoading] = useState(false);
  const [haveErrors, setHaveErrors] = useState(false);
  const [textErrors, setTextErrors] = useState("");
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);
  const [dialogDownloadVisible, setDialogDownloadVisible] = useState(false);
  const [dialogAddressVisible, setDialogAddressVisible] = useState(false);
  const [dialogShowFileVisible, setDialogShowFileVisible] = useState(false);
  const [dialogMapVisible, setDialogMapVisible] = useState(false);
  const [dialogValveVisible, setDialogValveVisible] = useState(false);
  const [dialogMassiveVisible, setDialogMassiveVisible] = useState(false);
  const [dialogHhuVisible, setDialogHhuVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [downloadTemplate, setDownloadTemplate] = useState(
    URL_API + "/public/templates/Plantilla_importacion.xlsx"
  );
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0,
    currentPage: 0,
  });
  const [paginationList, setPaginationList] = useState([
    { value: 10, name: "10 Registros Por Página" },
    { value: 20, name: "20 Registros Por Página" },
    { value: 50, name: "50 Registros Por Página" },
    { value: 100, name: "100 Registros Por Página" },
  ]);
  const [loraServiceList, setLoraServiceList] = useState([
    { lora_service: "Nube-1", name: "Nube 1" },
    { lora_service: "Local", name: "Local" },
    { lora_service: "Sigfox", name: "Sigfox" },
  ]);

  const [meterTypeList, setMeterTypeList] = useState([
    { meter_type_id: 1, name: "Chorro múltiple" },
    { meter_type_id: 2, name: "Chorro único" },
    { meter_type_id: 3, name: "Electromagnético" },
    { meter_type_id: 4, name: "Ultrasónico" },
    { meter_type_id: 5, name: "volumétrico" },
    { meter_type_id: 6, name: "Woltman" },
    { meter_type_id: 7, name: "Medidor de Pulsos" },
  ]);
  const [technicalNormsList, setTechnicalNormsList] = useState([
    { technical_norm_id: 1, name: "NTC 1063-1:2007" },
    { technical_norm_id: 2, name: "NTC ISO 4064-1:2016" },
  ]);
  const [flowList, setFlowList] = useState([
    { flow_id: 1, value: "1" },
    { flow_id: 2, value: "1.6" },
    { flow_id: 3, value: "2.5" },
    { flow_id: 4, value: "4" },
    { flow_id: 5, value: "6.3" },
    { flow_id: 6, value: "10" },
    { flow_id: 7, value: "16" },
    { flow_id: 8, value: "25" },
    { flow_id: 9, value: "40" },
    { flow_id: 10, value: "63" },
    { flow_id: 11, value: "100" },
    { flow_id: 12, value: "160" },
    { flow_id: 13, value: "250" },
    { flow_id: 14, value: "400" },
    { flow_id: 15, value: "630" },
    { flow_id: 16, value: "1000" },
    { flow_id: 17, value: "1600" },
    { flow_id: 18, value: "2500" },
    { flow_id: 19, value: "4000" },
    { flow_id: 20, value: "6300" },
  ]);

  const [relationQList, setRelationQList] = useState([
    { relationq_id: 1, value: "40" },
    { relationq_id: 2, value: "50" },
    { relationq_id: 3, value: "63" },
    { relationq_id: 4, value: "80" },
    { relationq_id: 5, value: "100" },
    { relationq_id: 6, value: "125" },
    { relationq_id: 7, value: "160" },
    { relationq_id: 8, value: "200" },
    { relationq_id: 9, value: "250" },
    { relationq_id: 10, value: "315" },
    { relationq_id: 11, value: "400" },
    { relationq_id: 12, value: "500" },
    { relationq_id: 13, value: "630" },
    { relationq_id: 14, value: "800" },
    { relationq_id: 15, value: "1000" },
    { relationq_id: 16, value: "1250" },
    { relationq_id: 17, value: "1600" },
    { relationq_id: 18, value: "2000" },
  ]);

  const [presicionClassList, setPresicionClassList] = useState([
    { presicion_id: 1, name: "CP 1" },
    { presicion_id: 2, name: "CP 2" },
  ]);

  const [intervalList, setIntervalList] = useState([
    { interval_id: 1, value: "9 999" },
    { interval_id: 2, value: "99 999" },
    { interval_id: 1, value: "999 999" },
    { interval_id: 2, value: "9 999 999" },
  ]);

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);
  const [devicesHhu, setDevicesHhu] = useState([]);
  const [selectionHhu, setSelectionHhu] = useState([]);
  const [inactiveDevices, setInactiveDevices] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [commands, setCommands] = useState([]);
  const [deviceProfileList, setDeviceProfileList] = useState([]);
  const [serviceProfileList, setServiceProfileList] = useState([]);

  // Variables de Estado de Formulario
  const [roadList, setRoadList] = useState([
    "Anillo",
    "Autopista",
    "Avenida",
    "Calle",
    "Carrera",
    "Circular",
    "Diagonal",
    "Transversal",
    "Kilometro",
    "Otro",
  ]);
  const [propertyType, setPropertyType] = useState([
    "Apartamento",
    "Bloque",
    "Casa",
    "Oficina",
  ]);
  const [urlApi, setUrlApi] = useState(URL_API + "/uploadDeviceImage");
  const [urlApiDevices, setUrlApiDevices] = useState(
    URL_API + "/uploadDevices"
  );
  const [urlApiHhu, setUrlApiHhu] = useState(URL_API + "/upload-reads-hhu");
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileDevices, setSelectedFileDevices] = useState();
  const [disabledFile, setDisabledFile] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [showInputValue, setShowInputValue] = useState(false);
  const [showInputId, setShowInputId] = useState(true);
  const [selectedFileHhu, setSelectedFileHhu] = useState();

  const [statusValve, setStatusValve] = useState({
    command_id: null,
    device_id: null,
    eui: "",
  });
  const [showImage, setShowImage] = useState({
    id: null,
    name: "",
    url: "",
    date: null,
  });
  const [rangeDate, setRangeDate] = useState({
    date_start: new Date(),
    date_end: new Date(),
  });

  const [downloadHistory, setDownloadHistory] = useState({
    eui: null,
    name: "",
    sectorName: "",
    date_start: new Date(),
    date_end: new Date(),
  });

  const [mapDevice, setMapDevice] = useState({
    latitud: 0,
    longitud: 0,
  });

  const [createDevice, setCreateDevice] = useState({
    eui: "",
    name: "",
    lora_service: "",
    serial: "",
    app_key: "",
    app_eui: "",
    device_profile_id: null,
    service_profile_id: null,
    organization_id: null,
    sector_id: null,
    contract_id: null,
    address: "",
    latitud: 0,
    longitud: 0,
    description: "",
    additional_address: "",
    postal_code: null,
    model_id: null,
    meter_value: 0,
    device_id: ""
  });

  const [editDevice, setEditDevice] = useState({
    eui: "",
    name: "",
    lora_service: "",
    serial: "",
    app_key: "",
    app_eui: "",
    device_profile_id: null,
    service_profile_id: null,
    organization_id: null,
    sector_id: null,
    contract_id: null,
    address: "",
    latitud: 0,
    longitud: 0,
    description: "",
    additional_address: "",
    postal_code: null,
    fileList: [],
    model_id: null,
    meter_value: 0,
    device_id: ""
  });

  const [dataModel, setDataModel] = useState({
    meter_type_id: null,
    technical_norm_id: null,
    flow_id: null,
    relationq_id: null,
    presicion_id: null,
    interval_id: null,
  });

  const [createAddress, setCreateAddress] = useState({
    road: "",
    propertyType: "",
    number1: "",
    number2: "",
    number3: "",
    number4: "",
    apto: "",
  });

  const [queryData, setQueryData] = useState({
    name: null,
    serial: null,
    sector_id: null,
    page: null,
    pageSize: null,
    eui: null,
  });

  var columns = [
    {
      label: "Nombre",
      prop: "name",
    },
    {
      label: "Serial",
      prop: "serial",
    },
    {
      label: "Sector",
      prop: "sector",
    },
    {
      label: "Última conexión",
      render: (data, column) => {
        if (data.date) {
          let dateObj = new Date(data.date);
          let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
          return dateFormat;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Último estado",
      prop: "last_state",
      render: (data, column) => {
        if (!data.status) {
          return (
            <Tag type="danger" style={{ width: "100%", textAlign: "center" }}>
              Sin conexión
            </Tag>
          );
        } else if (data.status) {
          if (data.status == "00" || data.status == "0") {
            return (
              <Tag
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                Sin error
              </Tag>
            );
          } else if (data.status == "04") {
            return (
              <Tag
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                Batería baja
              </Tag>
            );
          } else if (data.status == "08") {
            return (
              <Tag
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                Error de hardware
              </Tag>
            );
          } else if (data.status == "10") {
            return (
              <Tag
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                Tubería vacía
              </Tag>
            );
          } else if (data.status == "20") {
            return (
              <Tag
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                Fuga
              </Tag>
            );
          } else if (data.status == "A0") {
            return (
              <Tag
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                Alto flujo
              </Tag>
            );
          } else if (data.status == "60") {
            return (
              <Tag
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                Flujo inverso
              </Tag>
            );
          } else if (data.status == "80") {
            return (
              <Tag
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                Baja temperatura
              </Tag>
            );
          } else if (data.status == "70") {
            return (
              <Tag style={{ width: "100%", textAlign: "center" }}>
                Flujo inverso/Tubería vacía
              </Tag>
            );
          } else {
            return (
              <Tag style={{ width: "100%", textAlign: "center" }}>
                {data.alarm}
              </Tag>
            );
          }
        }
      },
    },
    {
      label: "Última lectura",
      prop: "last_read",
      render: (data, column) => {
        if (data.volume == null || data.volume == undefined) {
          return (
            <Tag type="danger" style={{ width: "100%", textAlign: "center" }}>
              Sin conexión
            </Tag>
          );
        } else {
          return (
            <Tag style={{ width: "100%", textAlign: "center" }}>
              <i className="fas fa-tachometer-alt"></i>{" "}
              {Math.round(data.volume * Math.pow(10, 3)) / Math.pow(10, 3)} m³
            </Tag>
          );
        }
      },
    },
    {
      label: "RSSI",
      width: "90px",
      render: (data, column, index) => {
        if (data.rssi && !data.device_id) {
          let rssi = parseFloat(data.rssi);

          if (rssi >= parseFloat(-67)) {
            return (
              <SignalSVG
                id={index + 1}
                fill0={"#36FFB5"}
                fill1={"#36FFB5"}
                fill2={"#36FFB5"}
                fill3={"#36FFB5"}
              />
            );
          } else if (rssi < parseFloat(-67) && rssi >= parseFloat(-100)) {
            return (
              <SignalSVG
                id={index + 2}
                fill0={"#FFD200"}
                fill1={"#FFD200"}
                fill2={"#FFD200"}
                fill3={"#868686"}
              />
            );
          } else if (rssi < parseFloat(-100) && rssi >= parseFloat(-110)) {
            return (
              <SignalSVG
                id={index + 3}
                fill0={"#FF8200"}
                fill1={"#FF8200"}
                fill2={"#868686"}
                fill3={"#868686"}
              />
            );
          } else if (rssi < parseFloat(-110)) {
            return (
              <SignalSVG
                id={index + 4}
                fill0={"#FF3232"}
                fill1={"#868686"}
                fill2={"#868686"}
                fill3={"#868686"}
              />
            );
          }
        } else {
          return 'N/A'
        }
      },
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (data) => {
        return (
          <Button.Group>
            <Button
              disabled={!props.roles.profiles.profile_edit}
              className="btn-edit"
              type="primary"
              icon="edit"
              size="mini"
              title="Editar"
              onClick={() => copyDataUpdate(data)}
            ></Button>
            <Button
              disabled={!props.roles.profiles.profile_delete}
              className="btn-delete"
              type="primary"
              icon="delete"
              size="mini"
              onClick={() => deviceDeleteApi(data.id)}
            ></Button>
            {props.roles.profiles.profile_see ? (
              <Link
                to={{
                  pathname: "/historial_dispositivo",
                  row: data,
                }}
              >
                <Button
                  disabled={!props.roles.profiles.profile_see}
                  className="btn-edit"
                  type="primary"
                  icon="search"
                  size="mini"
                ></Button>
              </Link>
            ) : null}

            <Button
              disabled={!props.roles.profiles.profile_see}
              className="btn-edit"
              type="primary"
              size="mini"
              onClick={() => copyDataDownload(data)}
            >
              <i className="fa fa-file-excel-o"></i>
            </Button>
            {/* <Button disabled={!props.roles.profiles.profile_see} className="btn-edit" type="primary" size="mini" onClick={() => copyShowMap(data)}><i className="fa fa-map-marker"></i></Button>*/}
            {props.roles.profiles.profile_see ? (
              <Link
                to={{
                  pathname: "/device_detail",
                  row: data,
                }}
              >
                <Button
                  disabled={!props.roles.profiles.profile_see}
                  className="btn-edit"
                  type="primary"
                  size="mini"
                >
                  <i className="fa fa-map-o"></i>
                </Button>
              </Link>
            ) : null}
            {data.is_valve ? (
              <Button
                disabled={!props.roles.profiles.profile_edit}
                className="btn-edit"
                type="primary"
                size="mini"
                title="Control Valvula"
                onClick={() => copyDataValve(data)}
              >
                <i className="fa fa-cog"></i>
              </Button>
            ) : (
              "         "
            )}
          </Button.Group>
        );
      },
    },
  ];

  var columnsInactiveDevices = [
    {
      label: "Nombre",
      prop: "device_name",
    },
    {
      label: "Serial",
      prop: "serial",
    },
    {
      label: "Sector",
      prop: "sector_name",
    },
    {
      label: "Última conexión",
      render: (data, column) => {
        if (data.date) {
          let newDate = date.addHours(new Date(data.date), -5);
          let dateFormat = date.format(newDate, "YYYY-MM-DD HH:mm:ss");
          return dateFormat;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Último estado",
      prop: "last_state",
      render: (data, column) => {
        if (data.status == null) {
          return (
            <Tag type="danger" style={{ width: "50%", textAlign: "center" }}>
              Sin conexión
            </Tag>
          );
        } else if (data.status == "00") {
          return (
            <Tag
              style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
              Sin error
            </Tag>
          );
        } else if (data.status == "04") {
          return (
            <Tag
              style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
              Batería baja
            </Tag>
          );
        } else if (data.status == "08") {
          return (
            <Tag
              style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
              Error de hardware
            </Tag>
          );
        } else if (data.status == "10") {
          return (
            <Tag
              style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
              Tubería vacía
            </Tag>
          );
        } else if (data.status == "20") {
          return (
            <Tag
              style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
              Fuga
            </Tag>
          );
        } else if (data.status == "A0") {
          return (
            <Tag
              style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
              Alto flujo
            </Tag>
          );
        } else if (data.status == "60") {
          return (
            <Tag
              style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
              Flujo inverso
            </Tag>
          );
        } else if (data.status == "80") {
          return (
            <Tag
              style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
              Baja temperatura
            </Tag>
          );
        } else if (data.status == "70") {
          return (
            <Tag style={{ width: "100%", textAlign: "center" }}>
              Flujo inverso/Tubería vacía
            </Tag>
          );
        }
      },
    },
    {
      label: "Última lectura",
      prop: "last_read",
      render: (data, column) => {
        if (data.volume == null) {
          return (
            <Tag type="danger" style={{ width: "100%", textAlign: "center" }}>
              Sin conexión
            </Tag>
          );
        } else {
          return (
            <Tag style={{ width: "100%", textAlign: "center" }}>
              <i className="fas fa-tachometer-alt"></i>{" "}
              {Math.round(data.volume * Math.pow(10, 3)) / Math.pow(10, 3)} m³
            </Tag>
          );
        }
      },
    },
  ];

  var FileColumns = [
    {
      label: "Nombre",
      prop: "name",
      width: "350px",
    },
    {
      label: "Imagen",
      width: "350px",
      render: (data, column) => {
        if (data.url) {
          return (
            <img
              src={data.url}
              className="image"
              alt=""
              height="50px"
              width="50px"
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      label: "Acciones",
      prop: "actions",
      width: "130px",
      render: (data) => {
        return (
          <Button.Group>
            <Button
              disabled={!props.roles.profiles.profile_delete}
              className="btn-delete"
              type="primary"
              icon="delete"
              size="mini"
              onClick={() => fileDeleteApi(data.id)}
            ></Button>
            <Button
              disabled={!props.roles.profiles.profile_see}
              className="btn-edit"
              type="primary"
              icon="search"
              size="mini"
              onClick={() => showFile(data)}
            >
              {" "}
            </Button>
          </Button.Group>
        );
      },
    },
  ];

  var columnsHhu = [
    {
      type: "selection",
    },
    {
      label: "Nombre",
      prop: "name",
    },
    {
      label: "Serial",
      prop: "serial",
    },
    {
      label: "Sector",
      prop: "sector",
    },
    {
      label: "Última conexión",
      render: (data, column) => {
        if (data.date) {
          let dateObj = new Date(data.date);
          let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
          return dateFormat;
        } else {
          return "-";
        }
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      let stringQuery = sessionStorage.getItem("queryData");
      if (stringQuery) {
        searchDevices(JSON.parse(stringQuery));
      }
      //else {

      //   var result = await paginationDeviceListRequest(pagination);

      //   if (result) {
      //     pagination.total = result.count;
      //     setData(result.rows);
      //   }
      // }
      var result = await sectorListRequest();
      if (result) {
        setSectors(result);
      }

      var result = await contractListRequest();
      if (result) {
        setContractList(result);
      }

      var result = await inactiveDevicesListRequest();
      if (result) {
        setInactiveDevices(result);
      }

      if (props && props.location.tab) {
        setActiveTab(props.location.tab.activeTab);
      }

      var result = await modelListRequest();
      if (result) {
        setModelList(result);
      }
    }
    fetchData();
    listDevices();
  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createDevice };
    newValues[key] = value;
    setCreateDevice(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editDevice };
    newValues[key] = value;
    setEditDevice(newValues);
  }

  // Validación del formulario de direcciones
  function validation() {
    createAddress.number1 = createAddress.number1.replace(/ /g, "");
    createAddress.number2 = createAddress.number2.replace(/ /g, "");
    createAddress.number3 = createAddress.number3.replace(/ /g, "");
    createAddress.propertyType = createAddress.propertyType.replace(/ /g, "");
    createAddress.number4 = createAddress.number4.replace(/ /g, "");
    createAddress.apto = createAddress.apto.replace(/ /g, "");

    if (createAddress.road.length == 0) {
      setTextErrors("La via no puede estar vacía.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number1.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number2.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number3.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else {
      setTextErrors("");
      setHaveErrors(false);
      return true;
    }
  }

  async function DeviceCreateApi() {
    setIsLoading(true);
    var values = createDevice;
    var result = await deviceCreateRequest(values);
    setIsLoading(false);

    if (result) {
      onFileUpload(result.id);
      cleanForm();
      setIsLoading(true);
      var result = await paginationDeviceListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
        setIsLoading(false);
      }
    }
  }

  async function copyDataUpdate(item) {
    let newItem = Object.assign({}, item);
    let eui = newItem.eui.replaceAll(":", "");
    newItem.latitud = parseFloat(item.latitud);
    newItem.longitud = parseFloat(item.longitud);
    showId(newItem.lora_service);
    //newItem.meter_value = parseFloat(item.meter_value);
    newItem.eui = eui;
    let profiles = await getDataForm({ modal: "edit" });
    let result = await getFilesByDevice({ device_id: newItem.id });
    setDeviceId(newItem.id);
    if (result) {
      setFileList(result);
      if (result.length >= 2) {
        setDisabledFile(true);
      }
      setEditDevice(newItem);
      showDataModel(newItem.model_id);
      if (profiles) {
        setDialogEditVisible(true);
      }
    }
  }

  // Muestra los datos del modelo en los formularios de edición y creación
  function showDataModel(item) {
    let found = modelList.find((element) => element.id === item);
    let newItem = Object.assign({}, found);
    setDataModel(newItem);
    setShowInputValue(newItem.is_pulse);
  }

  function copyShowMap(item) {
    let newItem = Object.assign({}, item);
    setMapDevice(newItem);
    setDialogMapVisible(true);
  }

  function onChangeAddres(key, value) {
    let newValues = { ...createAddress };
    newValues[key] = value;
    setCreateAddress(newValues);
  }

  function saveAddress() {
    if (validation()) {
      createDevice.address =
        createAddress.road +
        " " +
        createAddress.number1 +
        " # " +
        createAddress.number2 +
        " - " +
        createAddress.number3 +
        " " +
        createAddress.propertyType +
        " " +
        createAddress.number4 +
        " " +
        createAddress.apto;

      editDevice.address =
        createAddress.road +
        " " +
        createAddress.number1 +
        " # " +
        createAddress.number2 +
        " - " +
        createAddress.number3 +
        " " +
        createAddress.propertyType +
        " " +
        createAddress.number4 +
        " " +
        createAddress.apto;

      cleanFormAdres();
    }
  }

  function copyAdresToUpdate(address) {
    if (address) {
      let stringAddress = address;
      let arrrayAddress = stringAddress.split(" ");

      setCreateAddress({
        road: arrrayAddress[0],
        number1: arrrayAddress[1],
        number2: arrrayAddress[3],
        number3: arrrayAddress[5],
        propertyType: arrrayAddress[6],
        number4: arrrayAddress[7],
        apto: arrrayAddress[8],
      });
    }

    setDialogAddressVisible(true);
  }

  function cleanFormAdres() {
    setCreateAddress({
      road: "",
      propertyType: "",
      number1: "",
      number2: "",
      number3: "",
      number4: "",
      apto: "",
    });

    setDialogAddressVisible(false);
  }

  async function deviceUpdateApi() {
    var values = editDevice;

    var result = await deviceUpdateRequest(values);
    if (result) {
      onFileUpload(result[1][0].id);
      cleanForm();
      setIsLoading(true);
      var result = await paginationDeviceListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
        setIsLoading(false);
      }
    }
  }

  async function deviceDeleteApi(id) {
    MessageBox.confirm(
      "Está seguro que desea borrar este dispositivo?",
      "Advertencia",
      {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      }
    )
      .then(async () => {
        var result = await deviceDeleteRequest(id);
        if (result) {
          setIsLoading(true);
          var result = await paginationDeviceListRequest(pagination);
          if (result) {
            pagination.total = result.count;
            setData(result.rows);
            setIsLoading(false);
          }
        } else {
          setTextErrors(result.message);
          setHaveErrors(true);
        }
      })
      .catch(() => { });
  }

  function cleanForm() {
    setDialogCreateVisible(false);
    setDialogEditVisible(false);
    setDialogDownloadVisible(false);
    setTextErrors("");
    setHaveErrors(false);
    setFileList([]);
    setShowInputId(false);
    setCreateDevice({
      eui: "",
      name: "",
      lora_service: "",
      serial: "",
      app_key: "",
      app_eui: "",
      device_profile_id: null,
      service_profile_id: null,
      organization_id: null,
      sector_id: null,
      contract_id: null,
      address: "",
      latitud: 0,
      longitud: 0,
      description: "",
      additional_address: "",
      postal_code: null,
      model_id: null,
      meter_value: 0,
      device_id: ""
    });
    setEditDevice({
      eui: "",
      name: "",
      lora_service: "",
      serial: "",
      app_key: "",
      app_eui: "",
      device_profile_id: null,
      service_profile_id: null,
      organization_id: null,
      sector_id: null,
      contract_id: null,
      address: "",
      latitud: 0,
      longitud: 0,
      description: "",
      additional_address: "",
      postal_code: null,
      fileList: [],
      model_id: null,
      meter_value: 0,
      device_id: ""
    });
    setRangeDate({
      date_start: new Date(),
      date_end: new Date(),
    });
    setQueryData({
      name: null,
      serial: null,
      sector_id: null,
      page: 0,
      pageSize: 10,
      eui: null,
    });
    sessionStorage.clear();
  }

  async function onCurrentChange(page, size) {
    pagination.page = page;
    pagination.pageSize = size;
    pagination.currentPage = page + 1;
    searchDevices(null);
  }

  function copyDataDownload(item) {
    if (item) {
      let newItem = Object.assign({}, item);
      let newValues = { ...downloadHistory };
      newValues["eui"] = newItem.eui;
      newValues["name"] = newItem.name;
      newValues["serial"] = newItem.serial;
      newValues["sectorName"] = newItem.sector.name;
      setDownloadHistory(newValues);
    }
    setDialogDownloadVisible(true);
  }

  const handleInputChangeDate = (value) => {
    let newValues = { ...downloadHistory };
    newValues["date_start"] = value ? value[0] : null;
    newValues["date_end"] = value ? value[1] : null;
    setDownloadHistory(newValues);
  };

  async function downloadHistoryApi() {
    if (downloadHistory.date_start && downloadHistory.date_end) {
      let myDate = new Date();
      let myOffset = myDate.getTimezoneOffset() / -60;
      let tz = myOffset;
      let serialNumber = downloadHistory.serial
        ? downloadHistory.serial
        : "No definido";
      let eui = downloadHistory.eui;
      let serial = serialNumber;
      let name = downloadHistory.name;
      let sectorName = downloadHistory.sectorName;
      let date_start = date.format(
        downloadHistory.date_start,
        "YYYY-MM-DD HH:mm:ss.SSS"
      );
      let date_end = date.format(
        downloadHistory.date_end,
        "YYYY-MM-DD HH:mm:ss.SSS"
      );
      let organization_id = props.organizationId ? props.organizationId : null;

      var win = window.open(
        URL_API +
        `/device/downloadHistory?organization_id=${organization_id}&eui=${eui}&name=${name}&serial=${serial}&sectorName=${sectorName}&date_start=${date_start}&date_end=${date_end}&time_zone=${tz},_blank`
      );
      win.focus();
    } else {
      setTextErrors("Seleccione un rango de fechas");
      setHaveErrors(true);
    }
  }

  async function downloadDevicesApi() {
    setIsLoading(true);
    var values = { ...queryData };
    let name = values.name;
    let serial = values.serial;
    let sector_id = values.sector_id;
    let eui = values.eui;
    let organization_id = props.organizationId ? props.organizationId : null;
    let myDate = new Date();
    let myOffset = myDate.getTimezoneOffset() / -60;
    let tz = myOffset;

    var win = window.open(
      URL_API +
      "/device/downloadDevices" +
      "?organization_id=" +
      organization_id +
      "&name=" +
      name +
      "&serial=" +
      serial +
      "&sector_id=" +
      sector_id +
      "&time_zone=" +
      tz +
      "&eui=" +
      eui,
      "_blank"
    );
    win.focus();
    setIsLoading(false);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
    sessionStorage.setItem("queryData", JSON.stringify(newQuery));
  }

  async function searchDevices(data) {
    try {
      setIsLoading(true);
      var values = data ? data : { ...queryData };
      values.page = pagination.page;
      values.pageSize = pagination.pageSize;
      var devices = await paginationDeviceListRequest(values);
      if (devices) {
        setIsLoading(false);
        sessionStorage.clear();
        pagination["total"] = devices.count;
        setData(devices.rows);
        if (data) {
          setQueryData(data);
        }
      }
    } catch (error) {
      console.log("Hubo un error al obtener los dispositivos");
      console.error(error);
      setIsLoading(false);
    }
  }

  async function listDevices() {
    setIsLoading(true);
    var devices = await paginationDeviceListRequest(pagination);
    if (devices) {
      pagination.total = devices.count;
      setData(devices.rows);
      sessionStorage.setItem("queryData", JSON.stringify(queryData));
      setIsLoading(false);
    }
  }

  function onFileUpload(device_id) {
    if (selectedFile) {
      const formData = new FormData();

      formData.append("device_id", device_id);

      for (let index = 0; index < selectedFile.length; index++) {
        let file = selectedFile[index];
        formData.append("file", file, file.name);
      }

      // Request made to the backend api
      // Send formData object
      Axios.post(urlApi, formData, {
        headers: headers,
      });
    } else {
      return;
    }
  }

  async function onFileUploadDevices() {
    if (selectedFileDevices) {
      const formData = new FormData();

      let file = selectedFileDevices[0];
      formData.append("devices", file, file.name);

      // Request made to the backend api
      // Send formData object
      await Axios.post(urlApiDevices, formData, {
        headers: headers,
        responseType: "blob",
      }).then(async (response) => {
        if (response.status == 201) {
          Message({
            showClose: true,
            message: "Archivo procesado, verifique si contiene errores",
            type: "success",
            customClass: "message-position",
          });
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "Respuesta de importación.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          setDialogMassiveVisible(false);
          setSelectedFileDevices(null);
          var result = await paginationDeviceListRequest(pagination);
          if (result) {
            pagination.total = result.count;
            setData(result.rows);
          }
        } else if (response.status === 200) {
          Message({
            showClose: true,
            message: "Ocurrio un error durante la importación",
            type: "error",
            customClass: "message-position",
          });
        }
      });
    } else {
      return;
    }
  }

  function resetInput(e) {
    MessageBox.alert("Solo se permiten dos imagenes por dispositivo", "Alerta");
    e.target.value = null;
  }

  function fileDeleteApi(id) {
    MessageBox.confirm(
      "Está seguro que desea borrar este archivo?",
      "Advertencia",
      {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      }
    )
      .then(async () => {
        var result = await fileDeleteRequest(id);
        if (result) {
          let result = await getFilesByDevice({ device_id: deviceId });
          if (result) {
            setFileList(result);
            if (result.length >= 2) {
              setDisabledFile(true);
            } else {
              setDisabledFile(false);
            }
          }
        } else {
          setTextErrors(result.message);
          setHaveErrors(true);
        }
      })
      .catch(() => { });
  }

  function showFile(data) {
    let newDate = date.addHours(new Date(data.date), -5);
    data.date = date.format(newDate, "YYYY-MM-DD");
    setShowImage(data);
    setDialogShowFileVisible(true);
  }

  /*------------------------------------
  ********CONTROL DE VALVULA************
  -------------------------------------*/

  async function copyDataValve(item) {
    let model_id = item.model_id;
    let eui = item.eui.replaceAll(":", "");
    eui.toLowerCase();
    let newValues = { ...statusValve };
    newValues["eui"] = eui;
    newValues["device_id"] = item.id;
    setStatusValve(newValues);

    var result = await commandsByModel(model_id);
    if (result) {
      setCommands(result);
      setDialogValveVisible(true);
    }
  }

  function onChangeValve(key, value) {
    let newValues = { ...statusValve };
    newValues[key] = value;
    setStatusValve(newValues);
  }

  async function saveStatusValve() {
    setIsLoading(true);
    var values = statusValve;
    if (values.command_id) {
      var result = await statusValveRequest(values);
      setIsLoading(false);

      if (result.responseId) {
        setStatusValve({
          command_id: null,
          device_id: null,
          eui: "",
        });
        Message({
          showClose: true,
          message: "Se envió el comando correctamente",
          type: "success",
          customClass: "message-position",
        });
        setDialogValveVisible(false);
      }
    } else {
      Message({
        showClose: true,
        message: "Debe seleccionar un comando",
        type: "error",
        customClass: "message-position",
      });
    }
  }

  async function getDataForm(modal) {
    var device_profile = await deviceProfileRequest();
    if (device_profile) {
      setDeviceProfileList(device_profile);
    }
    var service_profile = await serviceProfileRequest();
    if (service_profile) {
      setServiceProfileList(service_profile);
    }
    if (modal.modal == "create") {
      setDialogCreateVisible(true);
    }
    if (service_profile) {
      return service_profile;
    }
  }

  async function _downloadTemplate() {
    var win = window.open(downloadTemplate, "_blank");
    win.focus();
  }

  /*------------------------------------
  ******** FUNCIONES PARA HAND HELD************
  -------------------------------------*/

  /**
   *  Obtiene la lista de medidores para seleccionar y exportar
   * @param value sector donde esta ubicado el dispositivos
   */
  async function getDataHhu(value) {
    let result = await getDevicesHhu({ sector_id: value });
    if (result) {
      setDevicesHhu(result);
      return;
    }
  }

  /**
   *  Obtiene la lista de medidores para seleccionar y exportar
   * @param value sector donde esta ubicado el dispositivos
   */
  async function openModalHhu() {
    await getDataHhu();
    setDialogHhuVisible(true);
  }

  /**
   *  Descarga los datos seleccionadas en csv
   */
  async function generateFileHhu() {
    let fielDelimiter = ",";
    let csvDelimiter = "\r\n";
    let str = `DevEui${fielDelimiter}DevId${fielDelimiter}Location${fielDelimiter}Longitude${fielDelimiter}Latitude${fielDelimiter}${csvDelimiter}`;

    // Obtener los datos
    const dateTemp = moment();
    const date = dateTemp.format("HHmm");
    let fileName = "";

    for (let index = 0; index < selectionHhu.length; index++) {
      const element = selectionHhu[index];
      element.longitud = element.longitud ? element.longitud.trim() : "";
      element.latitud = element.latitud ? element.latitud.trim() : "";
      element.eui = element.eui ? element.eui.trim() : "";
      element.serial = element.serial ? element.serial.trim() : "";
      let latitud = "";
      let longitud = "";
      let DevId = "";
      // formatear la latitud
      if (element.latitud) {
        latitud =
          element.latitud.length > 9
            ? element.latitud.substring(0, 9)
            : element.latitud;
      } else {
        latitud = "000.00000";
      }

      // formatear la longitud
      if (element.longitud) {
        longitud =
          element.longitud.length > 9
            ? element.longitud.substring(0, 9)
            : element.longitud;
      } else {
        longitud = "000.00000";
      }

      // formatear la DevId
      if (element.serial) {
        DevId =
          element.serial.length > 14
            ? element.serial.substring(0, 14)
            : await setFormatSerial(element.serial);
      } else {
        DevId = "00000000000000";
      }

      let DevEui = element.eui.replaceAll(":", "");
      //let DevId = element.serial;
      let Location = element.address ? element.address.replaceAll(",", "-") : "";
      let strTemp = `${DevEui}${fielDelimiter}${DevId}${fielDelimiter}${Location}${fielDelimiter}${longitud}${fielDelimiter}${latitud}${fielDelimiter}${csvDelimiter}`;
      str += strTemp;
    }

    fileName = `ID_${date}.csv`;

    const blob = new Blob([str], {
      type: "text/csv",
    });

    FileSaver.saveAs(blob, fileName);
  }

  /**
   *  Da formato de 14 caracteres al serial
   * @param serial serial del medidor
   * @returns string de 14 caracteres
   */
  async function setFormatSerial(serial) {
    let lengthSerial = serial.length;
    let stringconcat = 14 - lengthSerial;
    let id = "";

    for (let index = 0; index < stringconcat; index++) {
      id += "0";
    }

    let devId = id + serial;
    return devId;
  }

  async function onFileUploadHhu() {
    if (selectedFileHhu) {
      const formData = new FormData();

      let file = selectedFileHhu[0];
      formData.append("hhu", file, file.name);

      // Request made to the backend api
      // Send formData object
      await Axios.post(urlApiHhu, formData, {
        headers: headers,
        responseType: "blob",
      }).then(async (response) => {
        if (response.status == 201) {
          Message({
            showClose: true,
            message: "Archivo procesado correctamente",
            type: "success",
            customClass: "message-position",
          });

          setDialogHhuVisible(false);
          setSelectedFileHhu(null);
          var result = await paginationDeviceListRequest(pagination);
          if (result) {
            pagination.total = result.count;
            setData(result.rows);
            setSelectedFileHhu(null);
          }
        } else if (response.status === 200) {
          Message({
            showClose: true,
            message: "Ocurrio un error durante la importación",
            type: "error",
            customClass: "message-position",
          });
        }
      });
    } else {
      return;
    }
  }

  /*------------------------------------
 ********Sigfox************
 -------------------------------------*/
  // Muestra los campos pertenecientes a sigfox y oculta los lora
  function showId(value) {
    switch (value) {
      case 'Local':
        setShowInputId(true);
        break;
      case 'Nube-1':
        setShowInputId(true);
        break;
      case 'Sigfox':
        setShowInputId(false);
        break;
    }

  }

  return (
    <div className="container">
      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="2" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
          <br />
        </Layout.Col>

        <Layout.Col
          xs="24"
          sm={stateToolBar ? 19 : 24}
          className="toolbar-margins"
        >
          <Tabs
            className="tabs"
            activeName={activeTab}
            onTabClick={(tab) => console.log(tab.props.name)}
          >
            <Tabs.Pane label="Dispositivos" name="1">
              <Layout.Row style={{ padding: "20px" }} gutter="0">
                  <Button
                    disabled={!props.roles.profiles.profile_create}
                    className="btn-create"
                    type="primary"
                    onClick={() => getDataForm({ modal: "create" })}
                  >
                    Crear Nuevo Dispositivo
                  </Button>
                  <Button
                    disabled={!props.roles.profiles.profile_create}
                    className="btn-create"
                    type="primary"
                    onClick={() => setDialogMassiveVisible(true)}
                  >
                    Importar Dispositivos
                  </Button>
                  <Button
                    disabled={!props.roles.profiles.profile_create}
                    className="btn-create"
                    type="primary"
                    onClick={() => openModalHhu(true)}
                  >
                    HHU
                  </Button>
                  <Button
                    disabled={!props.roles.profiles.profile_see}
                    className="btn-create"
                    type="primary"
                    onClick={() => copyDataDownload()}
                  >
                    Histórico De Lecturas
                  </Button>
              </Layout.Row>

              {/* Formulario de busqueda */}
              <Form
                className="es-CO"
                model={queryData}
                labelWidth="120"
                labelPosition="top"
                style={{ padding: "10px" }}
              >
                <Layout.Row>
                  <Layout.Col span="22" offset="1">
                    <Layout.Row gutter="10">
                      <Layout.Col sm="4" xs="24">
                        <Form.Item className="label-filter" label="Nombre">
                          <Input
                            type="text"
                            value={queryData.name}
                            onChange={(value) =>
                              handleQueryChange("name", value)
                            }
                          />
                        </Form.Item>
                      </Layout.Col>
                      <Layout.Col sm="4" xs="24">
                        <Form.Item className="label-filter" label="Serial">
                          <Input
                            type="text"
                            value={queryData.serial}
                            onChange={(value) =>
                              handleQueryChange("serial", value)
                            }
                          />
                        </Form.Item>
                      </Layout.Col>
                      <Layout.Col xs="24" sm="4">
                        <Form.Item className="label-filter" label="EUI/ID">
                          <Input
                            type="text"
                            value={queryData.eui}
                            onChange={(value) => {
                              handleQueryChange("eui", value);
                            }}
                          />
                        </Form.Item>
                      </Layout.Col>
                      <Layout.Col sm="4" xs="24">
                        <Form.Item className="label-filter" label="Sectores">
                          <Select
                            style={{ width: "100%" }}
                            filterable={true}
                            clearable={true}
                            value={queryData.sector_id}
                            onChange={(value) =>
                              handleQueryChange("sector_id", value)
                            }
                          >
                            {sectors
                              ? sectors.map((item) => (
                                <Select.Option
                                  key={item.id}
                                  value={item.id}
                                  label={`${item.name}`}
                                />
                              ))
                              : null}
                          </Select>
                        </Form.Item>
                      </Layout.Col>
                      {/* <Layout.Col xs="24" sm="3"> */}
                        <Button
                          disabled={!props.roles.profiles.profile_see}
                          className="btn-create"
                          type="primary"
                          icon="search"
                          loading={loadingSearch}
                          onClick={() => searchDevices(null)}
                          style={{ marginTop: "23px" }}
                        >
                          Buscar
                        </Button>
                      {/* </Layout.Col> */}
                      {/* <Layout.Col xs="24" sm="3"> */}
                        <Button
                          disabled={!props.roles.profiles.profile_see}
                          className="btn-create"
                          type="primary"
                          style={{ marginTop: "23px" }}
                          loading={loadingSearch}
                          onClick={() => downloadDevicesApi(data)}
                        >
                          <i className="fa fa-file-excel-o"></i> Excel{" "}
                        </Button>
                      {/* </Layout.Col> */}
                      {/* <Layout.Col xs="24" sm="2"> */}
                        <Button
                          disabled={!props.roles.profiles.profile_see}
                          className="btn-delete"
                          type="primary"
                          icon="close"
                          onClick={() => {
                            cleanForm();
                            listDevices();
                          }}
                          style={{ marginTop: "23px" }}
                        ></Button>
                      {/* </Layout.Col> */}
                    </Layout.Row>
                  </Layout.Col>
                </Layout.Row>
              </Form>

              <Layout.Row style={{ padding: "20px", marginTop: "-8px" }}>
                <Table
                  style={{ width: "100%" }}
                  columns={columns}
                  data={data}
                  stripe={true}
                  highlightCurrentRow={true}
                  defaultExpandAll={true}
                  fit={true}
                  emptyText="No se encontraron Dispositivos"
                />
              </Layout.Row>

              <div className="block">
                <Loading text="Cargando..." loading={isLoading}></Loading>
                <Pagination
                  className="pagination"
                  layout="sizes, total, prev, pager, next"
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  pageSizes={[10, 20, 50, 100]}
                  currentPage={pagination.currentPage}
                  loading={loadingSearch}
                  onCurrentChange={(page) =>
                    onCurrentChange(page - 1, pagination.pageSize)
                  }
                  onSizeChange={(size) => onCurrentChange(0, size)}
                />
              </div>
            </Tabs.Pane>
            <Tabs.Pane
              label="Dispositivos inactivos por mas de 48 horas"
              name="2"
            >
              <Layout.Row style={{ padding: "20px" }}>
                <Table
                  style={{ width: "100%" }}
                  columns={columnsInactiveDevices}
                  data={inactiveDevices}
                  stripe={true}
                  highlightCurrentRow={true}
                  defaultExpandAll={true}
                  fit={true}
                  height={500}
                  emptyText="No se encontraron Dispositivos"
                />
              </Layout.Row>
            </Tabs.Pane>
          </Tabs>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/* Modal agregar */}
      <Dialog
        size="small"
        title="CREAR DISPOSITIVO"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Tabs
            activeName="device-create"
            onTabClick={(tab) => console.log(tab.props.name)}
          >
            <Tabs.Pane label="Dispositivo" name="device-create">
              <Form
                className="es-CO"
                model={createDevice}
                labelWidth="120"
                labelPosition="top"
              >
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Servicio">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Servicio"
                        filterable={true}
                        clearable={true}
                        value={createDevice.lora_service}
                        onChange={(value) => {
                          onChangeCreate("lora_service", value);
                          showId(value);
                        }}
                      >
                        {loraServiceList.map((item) => (
                          <Select.Option
                            key={item.lora_service}
                            label={`${item.name}`}
                            value={item.lora_service}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Nombre">
                      <Input
                        className="input-style"
                        placeholder="Ingrese un nombre"
                        value={createDevice.name}
                        onChange={(value) => {
                          onChangeCreate("name", value);
                        }}
                      />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    {showInputId ? (
                      <Form.Item label="EUI">
                        <Input
                          className="input-style"
                          placeholder="Ingrese una valor"
                          value={createDevice.eui}
                          onChange={(value) => {
                            onChangeCreate("eui", value);
                          }}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item label="Sigfox ID">
                        <Input
                          className="input-style"
                          placeholder="Ingrese una valor"
                          value={createDevice.device_id}
                          onChange={(value) => {
                            onChangeCreate("device_id", value);
                          }}
                        />
                      </Form.Item>
                    )}

                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm={showInputId ? '8' : '12'}>
                    <Form.Item label="Número de serial">
                      <Input
                        className="input-style"
                        placeholder="Ingrese un número de serial"
                        value={createDevice.serial}
                        onChange={(value) => {
                          onChangeCreate("serial", value);
                        }}
                      />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm={showInputId ? '8' : '12'}>
                    <Form.Item label="Marca-Modelo">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Modelo"
                        filterable={true}
                        clearable={true}
                        value={createDevice.model_id}
                        onChange={(value) => {
                          onChangeCreate("model_id", value);
                          showDataModel(value);
                        }}
                      >
                        {modelList.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={`${item.trademark.name}-${item.model}-${item.version}`}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  {showInputId ? (
                    <Layout.Col xs="24" sm="8">
                      <Form.Item label="App Eui">
                        <Input
                          className="input-style"
                          placeholder="Ingrese una valor"
                          value={createDevice.app_eui}
                          onChange={(value) => {
                            onChangeCreate("app_eui", value);
                          }}
                        />
                      </Form.Item>
                    </Layout.Col>
                  ) : ('')}
                </Layout.Row>
                {showInputId ? (
                  <Layout.Row gutter="20">
                    <Layout.Col xs="24" sm="8">
                      <Form.Item label="App Key">
                        <Input
                          className="input-style"
                          placeholder="Ingrese una valor"
                          value={createDevice.app_key}
                          onChange={(value) => {
                            onChangeCreate("app_key", value);
                          }}
                        />
                      </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="8">
                      <Form.Item label="Perfil de servicio">
                        <Select
                          className="input-style"
                          style={{ width: "100%" }}
                          placeholder="Perfil de servicio"
                          filterable={true}
                          clearable={true}
                          value={createDevice.service_profile_id}
                          onChange={(value) => {
                            onChangeCreate("service_profile_id", value);
                          }}
                        >
                          {serviceProfileList.map((item) => (
                            <Select.Option
                              key={item.Id}
                              label={`${item.Name}`}
                              value={item.Id}
                            />
                          ))}
                        </Select>
                      </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="8">
                      <Form.Item label="Perfil de dispositivo">
                        <Select
                          className="input-style"
                          style={{ width: "100%" }}
                          placeholder="Perfil de dispositivo"
                          filterable={true}
                          clearable={true}
                          value={createDevice.device_profile_id}
                          onChange={(value) => {
                            onChangeCreate("device_profile_id", value);
                          }}
                        >
                          {deviceProfileList.map((item) => (
                            <Select.Option
                              key={item.Id}
                              label={`${item.Name}`}
                              value={item.Id}
                            />
                          ))}
                        </Select>
                      </Form.Item>
                    </Layout.Col>
                  </Layout.Row>
                ) : ('')}
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Tipo de medidor">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Tipo de medidor"
                        disabled
                        value={dataModel.meter_type_id}
                      >
                        {meterTypeList.map((item) => (
                          <Select.Option
                            key={item.name + "_" + item.meter_type_id}
                            label={`${item.name}`}
                            value={item.meter_type_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Norma técnica">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Norma"
                        disabled
                        value={dataModel.technical_norm_id}
                      >
                        {technicalNormsList.map((item) => (
                          <Select.Option
                            key={item.technical_norm_id + "_" + item.name}
                            label={`${item.name}`}
                            value={item.technical_norm_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Caudal permantente Q3 m³/h">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Caudal"
                        disabled
                        value={dataModel.flow_id}
                      >
                        {flowList.map((item) => (
                          <Select.Option
                            key={item.flow_id + "_" + item.value}
                            label={`${item.value}`}
                            value={item.flow_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Relación Q3/Q1 “R” ">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Relación"
                        disabled
                        value={dataModel.relationq_id}
                      >
                        {relationQList.map((item) => (
                          <Select.Option
                            key={item.relationq_id + "_" + item.value}
                            label={`${item.value}`}
                            value={item.relationq_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Clase de presición">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Presición"
                        disabled
                        value={dataModel.presicion_id}
                      >
                        {presicionClassList.map((item) => (
                          <Select.Option
                            key={item.presicion_id + "_" + item.name}
                            label={`${item.name}`}
                            value={item.presicion_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Intervalo de indicación m³">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Intervalo"
                        disabled
                        value={dataModel.interval_id}
                      >
                        {intervalList.map((item) => (
                          <Select.Option
                            key={item.interval_id + "_" + item.value}
                            label={`${item.value}`}
                            value={item.interval_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  {showInputValue ? (
                    <Layout.Col xs="24" sm="24">
                      <Form.Item label="Medida inicial">
                        <InputNumber
                          className="input-style"
                          style={{ width: "100%" }}
                          placeholder="Ingrese la medida inicial del medidor"
                          min={-999999999.999}
                          max={999999999.999}
                          onChange={(value) => {
                            onChangeCreate("meter_value", value);
                          }}
                          value={createDevice.meter_value}
                        />
                      </Form.Item>
                    </Layout.Col>
                  ) : (
                    ""
                  )}
                </Layout.Row>

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Contrato">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        filterable={true}
                        clearable={true}
                        placeholder="Seleccione un contrato"
                        value={createDevice.contract_id}
                        onChange={(value) => {
                          onChangeCreate("contract_id", value);
                        }}
                      >
                        {contractList.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={`${item.contract} ${item.holder.first_name} ${item.holder.second_name} ${item.holder.first_surname} ${item.holder.second_surname}`}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Sector">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        clearable={true}
                        filterable={true}
                        placeholder="Seleccione un sector"
                        value={createDevice.sector_id}
                        onChange={(value) => {
                          onChangeCreate("sector_id", value);
                        }}
                      >
                        {sectors.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={`${item.name} | ${item.city.name}`}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="24">
                    <Form.Item label="Dirección principal">
                      <Input
                        className="input-style"
                        onChange={(value) => {
                          onChangeCreate("address", value);
                        }}
                        value={createDevice.address}
                      />
                    </Form.Item>
                  </Layout.Col>
                  {/* <Layout.Col xs="24" sm="2">
                    <Button
                      className="btn-create"
                      type="primary"
                      icon="edit"
                      onClick={() => setDialogAddressVisible(true)}
                      style={{ marginTop: "23px" }}
                    ></Button>
                  </Layout.Col> */}
                </Layout.Row>
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="18">
                    <Form.Item label="Dirección adicional">
                      <Input
                        className="input-style"
                        onChange={(value) => {
                          onChangeCreate("additional_address", value);
                        }}
                        value={createDevice.additional_address}
                      />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="6">
                    <Form.Item label="Código Postal">
                      <InputNumber
                        className="input-style"
                        onChange={(value) => {
                          onChangeCreate("postal_code", value);
                        }}
                        value={createDevice.postal_code}
                      />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20" style={{ marginTop: "10px" }}>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Latitud">
                      <Input
                        type="number"
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Ingrese una valor"
                        step="0.00000001"
                        min={-99.99999999}
                        max={99.99999999}
                        onChange={(value) => {
                          onChangeCreate("latitud", value);
                        }}
                        defaultValue={createDevice.longitud}
                      />
                    </Form.Item>
                  </Layout.Col>

                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Longitud">
                      <Input
                        type="number"
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Ingrese una valor"
                        step="0.00000001"
                        min={-99.99999999}
                        max={99.99999999}
                        onChange={(value) => {
                          onChangeCreate("longitud", value);
                        }}
                        defaultValue={createDevice.latitud}
                      />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Form.Item label="Descripción">
                  <Input
                    className="text-area-style"
                    placeholder="Ingrese una descripción"
                    type="textarea"
                    value={createDevice.description}
                    onChange={(value) => onChangeCreate("description", value)}
                  ></Input>
                </Form.Item>
              </Form>
            </Tabs.Pane>
            <Tabs.Pane label="Imágenes" name="Image-create">
              <Layout.Row style={{ padding: "20px" }}>
                <input
                  style={{ color: "#ffffff" }}
                  type="file"
                  accept="image/png, image/jpeg"
                  multiple
                  disabled={disabledFile}
                  onChange={(e) =>
                    e.target.files.length + fileList.length < 3
                      ? setSelectedFile(e.target.files)
                      : resetInput(e)
                  }
                />
              </Layout.Row>

              <Layout.Row style={{ padding: "20px" }}>
                <Table
                  style={{ width: "100%" }}
                  columns={FileColumns}
                  data={fileList}
                  stripe={true}
                  highlightCurrentRow={true}
                  height={300}
                  emptyText="No se encontraron imágenes"
                />
              </Layout.Row>
            </Tabs.Pane>
            <Tabs.Pane label="Ubicación" name="map-create">
              <Layout.Row style={{ padding: "20px" }}>
                <GoogleMap
                  coordinates={{
                    lat: createDevice.latitud,
                    lng: createDevice.longitud,
                  }}
                />
              </Layout.Row>
            </Tabs.Pane>
          </Tabs>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-save"
            type="primary"
            onClick={() => DeviceCreateApi()}
          >
            Guardar
          </Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal editar */}
      <Dialog
        size="small"
        title="EDITAR DISPOSITIVO"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Tabs
            activeName="device-edit"
            onTabClick={(tab) => console.log(tab.props.name)}
          >
            <Tabs.Pane label="Dispositivo" name="device-edit">
              <Form
                className="es-CO"
                model={editDevice}
                labelWidth="120"
                labelPosition="top"
              >
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Servicio">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Servicio"
                        filterable={true}
                        clearable={true}
                        value={editDevice.lora_service}
                        onChange={(value) => {
                          onChangeEdit("lora_service", value);
                          showId(value);
                        }}
                      >
                        {loraServiceList.map((item) => (
                          <Select.Option
                            key={item.lora_service}
                            label={`${item.name}`}
                            value={item.lora_service}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Nombre">
                      <Input
                        className="input-style"
                        placeholder="Ingrese un nombre"
                        value={editDevice.name}
                        onChange={(value) => {
                          onChangeEdit("name", value);
                        }}
                      />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    {showInputId ? (
                      <Form.Item label="EUI">
                        <Input
                          className="input-style"
                          placeholder="Ingrese una valor"
                          value={editDevice.eui}
                          onChange={(value) => {
                            onChangeEdit("eui", value);
                          }}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item label="Sigfox ID">
                        <Input
                          className="input-style"
                          placeholder="Ingrese una valor"
                          value={editDevice.device_id}
                          onChange={(value) => {
                            onChangeEdit("device_id", value);
                          }}
                        />
                      </Form.Item>
                    )}
                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm={showInputId ? '8' : '12'}>
                    <Form.Item label="Número de serial">
                      <Input
                        className="input-style"
                        placeholder="Ingrese un número de serial"
                        value={editDevice.serial}
                        onChange={(value) => {
                          onChangeEdit("serial", value);
                        }}
                      />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm={showInputId ? '8' : '12'}>
                    <Form.Item label="Marca-Modelo">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Modelo"
                        filterable={true}
                        clearable={true}
                        value={editDevice.model_id}
                        onChange={(value) => {
                          onChangeEdit("model_id", value);
                          showDataModel(value);
                        }}
                      >
                        {modelList.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={`${item.trademark.name}-${item.model}-${item.version}`}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  {showInputId ? (
                    <Layout.Col xs="24" sm="8">
                      <Form.Item label="App Eui">
                        <Input
                          className="input-style"
                          placeholder="Ingrese una valor"
                          value={editDevice.app_eui}
                          onChange={(value) => {
                            onChangeEdit("app_eui", value);
                          }}
                        />
                      </Form.Item>
                    </Layout.Col>
                  ) : ('')}
                </Layout.Row>

                {showInputId ? (
                  <Layout.Row gutter="20">
                    <Layout.Col xs="24" sm="8">
                      <Form.Item label="App Key">
                        <Input
                          className="input-style"
                          placeholder="Ingrese una valor"
                          value={editDevice.app_key}
                          onChange={(value) => {
                            onChangeEdit("app_key", value);
                          }}
                        />
                      </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="8">
                      <Form.Item label="Perfil de servicio">
                        <Select
                          className="input-style"
                          style={{ width: "100%" }}
                          placeholder="Perfil de servicio"
                          filterable={true}
                          clearable={true}
                          value={editDevice.service_profile_id}
                          onChange={(value) => {
                            onChangeEdit("service_profile_id", value);
                          }}
                        >
                          {serviceProfileList.map((item) => (
                            <Select.Option
                              key={item.Id}
                              label={`${item.Name}`}
                              value={item.Id}
                            />
                          ))}
                        </Select>
                      </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="8">
                      <Form.Item label="Perfil de dispositivo">
                        <Select
                          className="input-style"
                          style={{ width: "100%" }}
                          placeholder="Perfil de dispositivo"
                          filterable={true}
                          clearable={true}
                          value={editDevice.device_profile_id}
                          onChange={(value) => {
                            onChangeEdit("device_profile_id", value);
                          }}
                        >
                          {deviceProfileList.map((item) => (
                            <Select.Option
                              key={item.Id}
                              label={`${item.Name}`}
                              value={item.Id}
                            />
                          ))}
                        </Select>
                      </Form.Item>
                    </Layout.Col>
                  </Layout.Row>
                ) : ('')}
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Tipo de medidor">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Tipo de medidor"
                        disabled
                        value={dataModel.meter_type_id}
                      >
                        {meterTypeList.map((item) => (
                          <Select.Option
                            key={item.name + "_" + item.meter_type_id}
                            label={`${item.name}`}
                            value={item.meter_type_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Norma técnica">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Norma"
                        disabled
                        value={dataModel.technical_norm_id}
                      >
                        {technicalNormsList.map((item) => (
                          <Select.Option
                            key={item.technical_norm_id + "_" + item.name}
                            label={`${item.name}`}
                            value={item.technical_norm_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Caudal permantente Q3 m³/h">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Caudal"
                        disabled
                        value={dataModel.flow_id}
                      >
                        {flowList.map((item) => (
                          <Select.Option
                            key={item.flow_id + "_" + item.value}
                            label={`${item.value}`}
                            value={item.flow_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Relación Q3/Q1 “R” ">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Relación"
                        disabled
                        value={dataModel.relationq_id}
                      >
                        {relationQList.map((item) => (
                          <Select.Option
                            key={item.relationq_id + "_" + item.value}
                            label={`${item.value}`}
                            value={item.relationq_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Clase de presición">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Presición"
                        disabled
                        value={dataModel.presicion_id}
                      >
                        {presicionClassList.map((item) => (
                          <Select.Option
                            key={item.presicion_id + "_" + item.name}
                            label={`${item.name}`}
                            value={item.presicion_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Intervalo de indicación m³">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Intervalo"
                        disabled
                        value={dataModel.interval_id}
                      >
                        {intervalList.map((item) => (
                          <Select.Option
                            key={item.interval_id + "_" + item.value}
                            label={`${item.value}`}
                            value={item.interval_id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="24">
                    <Form.Item label="Medida inicial">
                      <InputNumber
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Ingrese la medida inicial del medidor"
                        min={-999999999.999}
                        max={999999999.999}
                        onChange={(value) => {
                          onChangeEdit("meter_value", value);
                        }}
                        value={editDevice.meter_value}
                      />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Contrato">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Seleccione un contrato"
                        filterable={true}
                        clearable={true}
                        value={editDevice.contract_id}
                        onChange={(value) => {
                          onChangeEdit("contract_id", value);
                        }}
                      >
                        {contractList.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={`${item.contract} ${item.holder.first_name} ${item.holder.second_name} ${item.holder.first_surname} ${item.holder.second_surname}`}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Sector">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        filterable={true}
                        clearable={true}
                        placeholder="Seleccione un sector"
                        value={editDevice.sector_id}
                        onChange={(value) => {
                          onChangeEdit("sector_id", value);
                        }}
                      >
                        {sectors.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={`${item.name} | ${item.city.name}`}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="24">
                    <Form.Item label="Dirección principal">
                      <Input
                        className="input-style"
                        onChange={(value) => {
                          onChangeEdit("address", value);
                        }}
                        value={editDevice.address}
                      />
                    </Form.Item>
                  </Layout.Col>
                  {/* <Layout.Col xs="24" sm="2">
                    <Button
                      className="btn-create"
                      type="primary"
                      icon="edit"
                      onClick={() => copyAdresToUpdate(editDevice.address)}
                      style={{ marginTop: "23px" }}
                    ></Button>
                  </Layout.Col> */}
                </Layout.Row>
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="18">
                    <Form.Item label="Dirección adicional">
                      <Input
                        className="input-style"
                        onChange={(value) => {
                          onChangeEdit("additional_address", value);
                        }}
                        value={editDevice.additional_address}
                      />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="6">
                    <Form.Item label="Código Postal">
                      <InputNumber
                        className="input-style"
                        onChange={(value) => {
                          onChangeEdit("postal_code", value);
                        }}
                        value={editDevice.postal_code}
                      />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20" style={{ marginTop: "10px" }}>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Latitud">
                      <Input
                        type="number"
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Ingrese una valor"
                        step="0.00000001"
                        min={-99.99999999}
                        max={99.99999999}
                        value={editDevice.latitud}
                        onChange={(value) => {
                          onChangeEdit("latitud", value);
                        }}
                        defaultValue={createDevice.longitud}
                      />
                    </Form.Item>
                  </Layout.Col>

                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Longitud">
                      <Input
                        type="number"
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Ingrese una valor"
                        step="0.00000001"
                        min={-99.99999999}
                        max={99.99999999}
                        value={editDevice.longitud}
                        onChange={(value) => {
                          onChangeEdit("longitud", value);
                        }}
                        defaultValue={createDevice.latitud}
                      />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Form.Item label="Descripción">
                  <Input
                    className="text-area-style"
                    placeholder="Ingrese una descripción"
                    type="textarea"
                    value={editDevice.description}
                    onChange={(value) => onChangeEdit("description", value)}
                  ></Input>
                </Form.Item>
              </Form>
            </Tabs.Pane>
            <Tabs.Pane label="Imágenes" name="image-edit">
              <Layout.Row style={{ padding: "20px" }}>
                <input
                  style={{ color: "#ffffff" }}
                  type="file"
                  accept="image/png, image/jpeg"
                  multiple
                  disabled={disabledFile}
                  onChange={(e) =>
                    e.target.files.length + fileList.length < 3
                      ? setSelectedFile(e.target.files)
                      : resetInput(e)
                  }
                />
              </Layout.Row>

              <Layout.Row style={{ padding: "20px" }}>
                <Table
                  style={{ width: "100%" }}
                  columns={FileColumns}
                  data={fileList}
                  stripe={true}
                  highlightCurrentRow={true}
                  height={300}
                  emptyText="No se encontraron imágenes"
                />
              </Layout.Row>
            </Tabs.Pane>
            <Tabs.Pane label="Ubicación" name="map-edit">
              <Layout.Row style={{ padding: "20px" }}>
                <GoogleMap
                  coordinates={{
                    lat: editDevice.latitud,
                    lng: editDevice.longitud,
                  }}
                />
              </Layout.Row>
            </Tabs.Pane>
          </Tabs>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-save"
            type="primary"
            onClick={() => deviceUpdateApi()}
          >
            Guardar
          </Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal seleccionar fechas */}
      <Dialog
        title={
          haveErrors ? (
            <Alert
              title={textErrors}
              type="error"
              showIcon={true}
              style={{ background: "#FF6A6A", width: "340px", float: "right" }}
            />
          ) : (
            ""
          )
        }
        size="small"
        visible={dialogDownloadVisible}
        onCancel={() => setDialogDownloadVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Layout.Row style={{ marginBottom: "41px" }}>
            <p style={{ fontSize: "16px", color: "#ffffff", margin: "0" }}>
              DESCARGAR HISTORIAL DISPOSITIVO
            </p>
          </Layout.Row>
          <Form
            className="es-CO"
            model={editDevice}
            labelWidth="120"
            labelPosition="top"
          >
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="24">
                <Form.Item label="Seleccione un rango de fecha">
                  <DateRangePicker
                    className="input-style"
                    isShowTime={true}
                    value={rangeDate}
                    placeholder="Rango de historial"
                    onChange={(date) => {
                      handleInputChangeDate(date);
                      setRangeDate(date);
                    }}
                  />
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-save"
            type="primary"
            onClick={() => downloadHistoryApi()}
          >
            Descargar
          </Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal formato de dirección */}
      <Dialog
        title={
          haveErrors ? (
            <Alert
              title={textErrors}
              type="error"
              showIcon={true}
              style={{ background: "#FF6A6A", width: "340px", float: "right" }}
            />
          ) : (
            ""
          )
        }
        size="small"
        visible={dialogAddressVisible}
        onCancel={() => setDialogAddressVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Layout.Row style={{ marginBottom: "41px" }}>
            <p style={{ fontSize: "16px", color: "#ffffff", margin: "0" }}>
              INGRESE LA DIRECCIÓN
            </p>
          </Layout.Row>
          <Form
            className="es-CO"
            model={createAddress}
            labelWidth="120"
            labelPosition="top"
          >
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="5">
                <Form.Item label="Vía">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    filterable={true}
                    clearable={true}
                    placeholder="Seleccione"
                    value={createAddress.road}
                    onChange={(value) => {
                      onChangeAddres("road", value);
                    }}
                  >
                    {roadList.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Número">
                  <Input
                    className="input-style"
                    value={createAddress.number1}
                    onChange={(value) => {
                      onChangeAddres("number1", value);
                    }}
                  ></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="1" sm="1">
                <label style={{ marginTop: "300px" }}>#</label>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input
                    className="input-style"
                    value={createAddress.number2}
                    onChange={(value) => {
                      onChangeAddres("number2", value);
                    }}
                  ></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="1">
                <Form.Item label="-"></Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input
                    className="input-style"
                    value={createAddress.number3}
                    onChange={(value) => {
                      onChangeAddres("number3", value);
                    }}
                  ></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="5">
                <Form.Item label="Tipo inmueble">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione"
                    filterable={true}
                    clearable={true}
                    value={createAddress.propertyType}
                    onChange={(value) => {
                      onChangeAddres("propertyType", value);
                    }}
                  >
                    {propertyType.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input
                    className="input-style"
                    disabled={createAddress.propertyType != "Bloque"}
                    value={createAddress.number4}
                    onChange={(value) => {
                      onChangeAddres("number4", value);
                    }}
                  />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Interior">
                  <Input
                    className="input-style"
                    value={createAddress.apto}
                    onChange={(value) => {
                      onChangeAddres("apto", value);
                    }}
                  ></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-save"
            type="primary"
            onClick={() => saveAddress()}
          >
            Crear
          </Button>
          <Button className="btn-cancel" onClick={() => cleanFormAdres()}>
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal ampliar imagenes */}
      <Dialog
        size="small"
        visible={dialogShowFileVisible}
        onCancel={() => setDialogShowFileVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Card className="box-card" style={{ borderRadius: "20px" }}>
            <img
              src={showImage.url}
              className="image"
              height="540px"
              width="900px"
            />
            <div style={{ padding: 14 }}>
              <span>{showImage.name}</span>
              <div className="bottom clearfix">
                <time className="time">{showImage.date}</time>
              </div>
            </div>
          </Card>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-cancel"
            onClick={() => setDialogShowFileVisible(false)}
          >
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal Google Maps*/}
      <Dialog
        title="UBICACIÓN"
        size="small"
        visible={dialogMapVisible}
        onCancel={() => setDialogMapVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Layout.Row style={{ padding: "20px" }}>
            <GoogleMap
              coordinates={{ lat: mapDevice.latitud, lng: mapDevice.longitud }}
            />
          </Layout.Row>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-cancel"
            onClick={() => setDialogMapVisible(false)}
          >
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal Control de Valvula*/}
      <Dialog
        title="CONTROL VÁLVULA"
        size="tiny"
        visible={dialogValveVisible}
        onCancel={() => setDialogValveVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Form>
            <Form.Item className="label-filter" label="Seleccione una opción">
              <Select
                style={{ marginTop: "10px", width: "100%" }}
                value={statusValve.command_id}
                onChange={(value) => {
                  onChangeValve("command_id", value);
                }}
              >
                {commands.map((item) => (
                  <Select.Option
                    key={item.id}
                    label={item.name}
                    value={item.id}
                  />
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-save"
            type="primary"
            onClick={() => saveStatusValve()}
          >
            Enviar
          </Button>
          <Button
            className="btn-cancel"
            onClick={() => setDialogValveVisible(false)}
          >
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal subida masiva de dispositivos*/}
      <Dialog
        title="IMPORTAR DISPOSITIVOS"
        size="tiny"
        visible={dialogMassiveVisible}
        onCancel={() => setDialogMassiveVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Form>
            <Layout.Row style={{ padding: "20px" }}>
              <input
                style={{ color: "#ffffff" }}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => setSelectedFileDevices(e.target.files)}
              />
            </Layout.Row>
            <Layout.Row style={{ padding: "20px" }}>
              <Button
                className="btn-cancel"
                onClick={() => _downloadTemplate()}
              >
                Descargar Plantilla
              </Button>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-save"
            type="primary"
            onClick={() => onFileUploadDevices()}
          >
            Enviar
          </Button>
          <Button
            className="btn-cancel"
            onClick={() => setDialogMassiveVisible(false)}
          >
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal cargue y descargue datos hand held*/}
      <Dialog
        title="CARGUE Y DESCARGUE HAND HELD"
        size="small"
        visible={dialogHhuVisible}
        onCancel={() => setDialogHhuVisible(false)}
        lockScroll={true}
        showClose={false}
      >
        <Dialog.Body>
          <Form>
            <Layout.Row style={{ padding: "20px" }}>
              <Layout.Col xs="24" sm="24">
                <Card
                  className="box-card"
                  header={
                    <div className="clearfix">
                      <span>Importar lecturas</span>
                    </div>
                  }
                >
                  <Layout.Row style={{ padding: "20px" }}>
                    <Layout.Col xs="24" sm="12">
                      <input
                        style={{ color: "#000000" }}
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => setSelectedFileHhu(e.target.files)}
                      />
                    </Layout.Col>
                    <Layout.Col xs="24" sm="12">
                      <Button
                        className="btn-cancel"
                        onClick={() => onFileUploadHhu()}
                      >
                        Guardar lecturas
                      </Button>
                    </Layout.Col>
                  </Layout.Row>
                </Card>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row style={{ padding: "20px" }}>
              <Layout.Col xs="24" sm="6">
                <Form.Item>
                  <Select
                    placeholder='Seleccione sector'
                    style={{ width: "100%" }}
                    filterable={true}
                    clearable={true}
                    value={queryData.sector_id}
                    onChange={(value) => getDataHhu(value)}
                  >
                    {sectors
                      ? sectors.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          label={`${item.name}`}
                        />
                      ))
                      : null}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Button style={{ marginLeft: "40px" }}
                  className="btn-cancel"
                  onClick={() => generateFileHhu()}
                >
                  Descargar
                </Button>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row style={{ padding: "20px" }}>
              <Table
                style={{ width: "100%" }}
                columns={columnsHhu}
                data={devicesHhu}
                border={true}
                height={450}
                stripe={true}
                onSelectChange={(selection) => {
                  setSelectionHhu(selection);
                }}
                onSelectAll={(selection) => { }}
              />
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            className="btn-cancel"
            onClick={() => setDialogHhuVisible(false)}
          >
            Cancelar
          </Button>
        </Dialog.Footer>
      </Dialog>
    </div >
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(Device);
